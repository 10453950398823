import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Navbar from "./components/Nav/Navbar";
import LandingPage from "./pages/LandingPage";
import "./styles/styles.css";
import * as api from "./api";
import BookingPage from "./pages/BookingPage";
import Login from "./components/Auth/Login";
import Overlay from "./components/Utils/Overlay";
import Register from "./components/Auth/Register";
import PaymentConfirmation from "./components/Payment/PaymentConfirmation";
import PaymentConfirmationRedirect from "./components/Payment/PaymentConfirmationRedirect";
import MyOrders from "./pages/MyOrders";
import EventDetails from "./pages/EventDetails";
import Help from "./pages/Help";
import UpgradeTicket from "./pages/UpgradeTicket";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Presales from "./pages/Presales";
import PresalesRedirect from "./pages/PresalesRedirect";
import PresalesReceipt from "./pages/PresalesReceipt";
import ExpiryPopup from "./components/Utils/ExpiryPopup";
import { hardcoded_event } from "./constants";
import Friendpay from "./pages/FriendPay";
import qs from "qs";
import Profile from "./pages/Profile";
import PrivacyPolicy from "./components/Utils/PrivacyPolicy";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [expiry, setExpiry] = useState(null);
  const [registrationHeader, setRegistrationHeader] = useState({});
  const [newUser, setNewUser] = useState({});
  const [activeOrder, setActiveOrder] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    let email_verified = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).email_verified;
    if (email_verified) {
      setNewUser({
        message: "Your email has been verified, please login to continue.",
        email: "",
        messageLine2: "",
      });
      setShowLogin(true);
    }
    fetchUser();
    window.addEventListener("resize", resizeVH);
    resizeVH();
    return () => {
      window.removeEventListener("resize", resizeVH);
    };
  }, []);

  useEffect(() => {
    if (!location.pathname.includes("/booking")) {
      setActiveOrder(false);
      setExpiry(null);
    }
    if (location.pathname !== "/") {
      window?.dataLayer?.push({
        event: "pageview",
        page: {
          url: window.location.href,
          title: location.pathname,
        },
      });
    }
  }, [location.pathname]);

  const resizeVH = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const fetchUser = () => {
    api.auth
      .me()
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          let genderObject = {};
          if (response.data.gender === "f") {
            genderObject.label = "Female";
            genderObject.value = "f";
          } else if (response.data.gender === "m") {
            genderObject.label = "Male";
            genderObject.value = "m";
          }
          setUser({
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            email: response.data.email,
            mobile: response.data.mobileNumber,
            pk: response.data.customerId,
            gender: genderObject,
          });
          setIsLoggedIn(true);
          setUserLoading(false);
        } else {
          setIsLoggedIn(false);
          setUserLoading(false);
        }
      })
      .catch(() => {
        setIsLoggedIn(false);
        setUserLoading(false);
      });
  };

  const handleLogin = (firstName, lastName, gender, email, mobile, id) => {
    let genderObject = {};
    if (gender === "f") {
      genderObject.label = "Female";
      genderObject.value = "f";
    } else if (gender === "m") {
      genderObject.label = "Male";
      genderObject.value = "m";
    }
    setUser({
      firstName,
      lastName,
      gender: genderObject,
      email,
      mobile,
      pk: id,
    });

    setIsLoggedIn(true);
  };

  const handleLogoutClick = () => {
    if (activeOrder) {
      setShowLogoutConfirmation(true);
    } else {
      handleLogout();
    }
  };

  const handleLogout = () => {
    api.auth.logout();
    setIsLoggedIn(false);
    setUser({});
    setShowLogoutConfirmation(false);
  };

  const handleRegistrationPopup = (form, type) => {
    if (form === "login") {
      setShowLogin(type);
    } else if (form === "register") {
      setShowRegister(type);
    } else if (form === "forgotPassword") {
      setShowForgotPassword(type);
    }
  };

  const manuallyLogUserIn = (email, password) => {
    api.auth
      .login(email, password)
      .then((response) => {
        handleLogin(
          response.data.firstName,
          response.data.lastName,
          response.data.gender,
          response.data.email,
          response.data.mobileNumber,
          response.data.pk
        );
      })
      .catch(() => {
        setUser({});
        setIsLoggedIn(false);
      });
  };

  return (
    <div className="App">
      <Navbar
        isLoggedIn={isLoggedIn}
        user={user}
        handleLogout={handleLogoutClick}
        handleRegistrationPopup={handleRegistrationPopup}
        expiry={expiry}
        activeOrder={activeOrder}
      />
      <Switch>
        {hardcoded_event === null && (
          <Route exact path="/booking" component={LandingPage} />
        )}
        <Route exact path="/events/:id" render={(props) => <EventDetails />} />
        <Route
          exact
          path="/booking/:id"
          render={(props) => (
            <BookingPage
              {...props}
              user={user}
              userLoading={userLoading}
              showLogin={setShowLogin}
              isLoginVisible={showLogin}
              handleRegistrationPopup={handleRegistrationPopup}
              setRegistrationHeader={setRegistrationHeader}
              setActiveOrder={setActiveOrder}
              expiry={expiry}
              setExpiry={setExpiry}
            />
          )}
        />
        <Route
          exact
          path="/orders/:id"
          render={(props) => (
            <PaymentConfirmation
              {...props}
              user={user}
              setShowLogin={setShowLogin}
              userLoading={userLoading}
            />
          )}
        />
        <Route
          exact
          path="/payment-confirmation"
          component={PaymentConfirmationRedirect}
        />
        <Route
          exact
          path="/orders"
          render={(props) => (
            <MyOrders
              {...props}
              user={user}
              setShowLogin={setShowLogin}
              userLoading={userLoading}
            />
          )}
        />
        <Route
          exact
          path="/profile"
          render={(props) => (
            <Profile
              {...props}
              user={user}
              setShowLogin={setShowLogin}
              userLoading={userLoading}
              setUser={setUser}
              handleLogin={manuallyLogUserIn}
            />
          )}
        />
        <Route exact path="/help" component={Help} />
        <Route exact path="/upgrade-ticket/:token" component={UpgradeTicket} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/invitations/" component={Presales} />
        <Route
          exact
          path="/invitations/redirect"
          component={PresalesRedirect}
        />
        <Route exact path="/orders/friend-pay/:id/" component={Friendpay} />
        <Route
          exact
          path="/invitations/receipt/:id/"
          component={PresalesReceipt}
        />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Redirect to={`/booking/${hardcoded_event || ""}`} />
      </Switch>
      {showForgotPassword && (
        <React.Fragment>
          <ForgotPassword
            close={() => handleRegistrationPopup("forgotPassword", false)}
            handleRegistrationPopup={handleRegistrationPopup}
            registrationHeader={registrationHeader}
          />
          <Overlay
            handleOnClick={() => handleRegistrationPopup("login", false)}
          />
        </React.Fragment>
      )}
      {showLogin && (
        <React.Fragment>
          <Login
            handleLogin={handleLogin}
            close={() => handleRegistrationPopup("login", false)}
            handleRegistrationPopup={handleRegistrationPopup}
            registrationHeader={registrationHeader}
            newUser={newUser}
            setNewUser={setNewUser}
          />
          <Overlay
            handleOnClick={() => handleRegistrationPopup("login", false)}
          />
        </React.Fragment>
      )}
      {showRegister && (
        <React.Fragment>
          <Register
            close={() => handleRegistrationPopup("register", false)}
            handleRegistrationPopup={handleRegistrationPopup}
            registrationHeader={registrationHeader}
            setNewUser={setNewUser}
          />
          <Overlay
            handleOnClick={() => handleRegistrationPopup("register", false)}
          />
        </React.Fragment>
      )}
      {showLogoutConfirmation && (
        <ExpiryPopup
          title="Timer"
          message="You currently have a standing purchase, by signing out you will lose your fetched passes."
          actionMessage="Are you sure you want to sign out?"
          handleBtn1Click={() => setShowLogoutConfirmation(false)}
          handleBtn2Click={handleLogout}
          btn1="No, Dont't sign me out"
          btn2="Yes, Sign me out"
          closable={true}
          close={() => setShowLogoutConfirmation(false)}
          expiry={expiry}
        />
      )}
    </div>
  );
};

export default App;
