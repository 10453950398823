import React, { useState, useEffect } from "react";
import * as api from "../../api";
import Addons from "../Utils/Addons";
import CTAPopup from "./CTAPopup";
import CheckoutDetails from "./CheckoutDetails";
import { ReactComponent as Arrow } from "../../assets/images/long-arrow-right.svg";

const CheckOut = ({
  user = {},
  id = "",
  event = {},
  tickets = {},
  expiry = null,
  selectedAddons = {},
  setSelectedAddons = () => {},
  subtotal = 0,
  setSubtotal = () => {},
  showReceipt = false,
  setShowReceipt = () => {},
  grandTotal = 0,
  setGrandTotal = () => {},
  nextStep = () => {},
  cancelOrder = () => {},
  tempCart = {},
  setTempCart = () => {},
  addons = [],
  setAddons = () => {},
  handleStepClick = () => {},
  editGuests = false,
}) => {
  const [totalTickets, setTotalTickets] = useState(0);

  useEffect(() => {
    getCartTotal();
  }, [tickets, tempCart, selectedAddons]);

  const getCartTotal = () => {
    let sum = 0;
    let price = 0;
    let cart = event.type === "full" ? { ...tempCart } : { ...tickets };
    for (const ticket in cart) {
      sum += cart[ticket].quantity;
      price += cart[ticket].quantity * cart[ticket].price;
    }
    for (const addon in selectedAddons) {
      price += selectedAddons[addon].price * selectedAddons[addon].count;
    }
    setTotalTickets(sum);
    setSubtotal(price);
  };

  const handleAddonChange = (e, id, price, name, type) => {
    if (e.target.checked) {
      let count = 0;
      if (type === "per unit" || type === "fixed") {
        count = 1;
      } else if (type === "bulk") {
        count = totalTickets;
      }
      setSelectedAddons({
        ...selectedAddons,
        [id]: {
          count,
          price,
          name,
          type,
        },
      });
    } else {
      let current = { ...selectedAddons };
      delete current[id];
      setSelectedAddons(current);
    }
  };

  const handleAddonCountChange = (id, type, price, name) => {
    let current = { ...selectedAddons };
    if (!current[id] && type === "subtract") {
      return;
    } else if (!current[id] && type === "add") {
      current[id] = { count: 1, price, name };
    } else if (current[id] && type === "subtract") {
      if (current[id].count - 1 > 0) {
        current[id].count -= 1;
      } else if (current[id].count - 1 === 0) {
        delete current[id];
      }
    } else if (current[id] && type === "add") {
      current[id].count += 1;
    }
    setSelectedAddons(current);
  };

  const handleAddonCollapse = (index) => {
    let current = [...addons];
    current[index].collapsed = !current[index].collapsed;
    setAddons(current);
  };

  return (
    <React.Fragment>
      <div className="event-page__body event-page__body--checkout">
        <div className="checkout__payment">
          <h4 className="checkout__subtitle">
            {user?.name ? user.firstName + ", " : ""}Select your add-ons
          </h4>
          {addons.length > 0 && (
            <React.Fragment>
              <Addons
                addons={addons}
                selectedAddons={selectedAddons}
                handleAddonChange={handleAddonChange}
                handleAddonCountChange={handleAddonCountChange}
                handleAddonCollapse={handleAddonCollapse}
              />
            </React.Fragment>
          )}
        </div>
        <CheckoutDetails
          subtotal={subtotal}
          showReceipt={showReceipt}
          setShowReceipt={setShowReceipt}
          grandTotal={grandTotal}
          event={event}
          tickets={event.type === "full" ? tempCart : tickets}
          selectedAddons={selectedAddons}
          setGrandTotal={setGrandTotal}
          totalTickets={totalTickets}
          cancelOrder={cancelOrder}
          handleStepClick={handleStepClick}
          editGuests={editGuests}
        />
      </div>
      <CTAPopup
        actionHandler={nextStep}
        title=""
        variation="single"
        text="Proceed to payment"
        textIcon={<Arrow className="cta-popup__arrow-icon" />}
        disabled={false}
        expiry={expiry}
        /* tooltipShown={checkoutRenderCount === 1} */
      />
    </React.Fragment>
  );
};

export default CheckOut;
