import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const payReservation = async (reservation, order, type, sendEmail) => {
    const response = await axios.post(
        routes.payReservation(reservation, order),
        {
            type,
            sendEmail,
        },
        AXIOS_DEFAULT_CONFIG
    );
    return response;
};
export default payReservation;
