import React from "react";
import CardIcon from "../../assets/images/visa-icon.svg";
import CardIcon2 from "../../assets/images/mastercard-icon.svg";
import SymplLogo from "./../../assets/images/sympl-logo.png";

const PaymentMethods = ({
  handlePaymentMethodSelect = () => {},
  selectedPayment = "",
  showSympl = false,
}) => {
  return (
    <div className="payment-methods-list">
      <div
        className={
          "payment-method" +
          (selectedPayment === "card" ? " payment-method--selected" : "")
        }
      >
        <label htmlFor="card" className="payment-method__body">
          <label className="custom-radio">
            <input
              type="radio"
              checked={selectedPayment === "card"}
              onChange={(e) => handlePaymentMethodSelect(e.target.value)}
              value="card"
              name="paymentmethod"
              id="card"
            />
            <span className="checkmark"></span>
          </label>
          <p className="payment-method__name">Credit card<br/>
          <span className="payment-method__description">International charges may apply. Please note that some credit card payments may be declined due to regulatory reasons. If you experience difficulties please try again with a different card.</span></p>

          <div className="payment-method__icons">
            <img
              src={CardIcon}
              alt="visa"
              className="payment-method__icon payment-method__icon--image"
            />
            <img
              src={CardIcon2}
              alt="MasterCard"
              className="payment-method__icon payment-method__icon--image"
            />
          </div>
        </label>
      </div>
      {showSympl && (
        <div
          className={
            "payment-method" +
            (selectedPayment === "installment" ? " payment-method--selected" : "")
          }
        >
          <label htmlFor="installment" className="payment-method__body">
            <label className="custom-radio">
              <input
                type="radio"
                checked={selectedPayment === "installment"}
                onChange={(e) => handlePaymentMethodSelect(e.target.value)}
                value="installment"
                name="paymentmethod"
                id="installment"
              />
              <span className="checkmark"></span>
            </label>
            <p className="payment-method__name">Sympl</p>
            <div className="payment-method__icons">
              <img
                src={SymplLogo}
                alt="sympl"
                className="payment-method__icon payment-method__icon--image"
              />

            </div>
          </label>
        </div>
      )}
    </div>
  );
};

export default PaymentMethods;
