import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

const ticketTiers = async (id, token = "") => {
    const response = await axios.get(
      routes.ticketTiers(id) + (token ? `?token=${token}` : ""),
      AXIOS_DEFAULT_CONFIG
    );
    return response;
  };
  export default ticketTiers;
  